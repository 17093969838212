import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { connect } from "react-redux";
import { currency, decrypt, logoController } from '../../../dynamicController';

import cpPlusBlueLogo from "../../../assets/images/logo/Logo_Cleanproplus-blue.png";
import cpPlusLogo from "../../../assets/images/logo/Logo_Cleanproplus-white.png";
import cpPlusWasher from "../../../assets/images/machines/icn-washer.png";
import cpPlusDryer from "../../../assets/images/machines/icn-dryer.png";
import locationIcon from "../../../assets/images/icons/location.png";
import confettiGif from "../../../assets/gif/ribbon.gif";

// import washer from "../../../assets/images/machines/paymentWasher.png"
// import dryer from "../../../assets/images/machines/paymentDryer.png"
import machineIcon from "../../../assets/images/icons/machine.png";
import timerClothIcon from "../../../assets/images/icons/timer-cloth-icon.png";
import timerMachineIcon from "../../../assets/images/icons/timer-machine-icon.png";
import timerHomeIcon from "../../../assets/images/icons/timer-home-icon.png";
import laundroLogo from "../../../assets/images/logo/laundroBlueLogo.png";

import { changeNav } from "../../../redux/actions/userActions";
import { scannedCode } from "../../../redux/actions/paymentActions";

import { ProgressBar } from 'react-bootstrap';

import { Trans } from 'react-i18next';
import { useTranslation } from "react-i18next";
import axios from "axios";
import io from 'socket.io-client';

const mySwal = withReactContent(Swal);

const STORETYPE = process.env.REACT_APP_STORETYPE;

const Thankyou = ({ themeLogo, payment, user, changeNav, transactionId, operatorId, scanned, transactionType, token }) => {

    const history = useHistory();
    const { t } = useTranslation();

    const [showTimer, setShowTimer] = useState(false);
    const [lastTran, setLastTran] = useState(null);
    const [running, setRunning] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [second, setSecond] = useState(0);
    const [country, setCountry] = useState("")

    let timer;

    useEffect(() => {
        if (STORETYPE !== "cleanpro") {
            mySwal.fire({
                title: t("Give us your feedback!"),
                text: t("Do you want to submit your feedback?"),
                icon: "question",
                showCancelButton: true,
                cancelButtonText: t("No"),
                confirmButtonText: t("Yes"),
                customClass: {
                    confirmButton: 'submit-feedback-yes',
                    cancelButton: 'submit-feedback-no'
                },
            }).then(click => {
                if (click.isConfirmed && click.value) history.push("/user/feedback");
            })
        }

        if (!lastTran && transactionId && transactionType === "Transaction") {
            axios
                .post("/api/user/cycle/check", { transactionId })
                .then(res => {
                    setLastTran(res.data.data)
                    setShowTimer(true)
                    if (res.data.second) {
                        const remainingTime = new Date(res.data.data.machine_end_time).getTime() - new Date().getTime()
                        const remainingSecond = Math.floor(remainingTime / 1000)
                        if (parseInt(remainingSecond / 60) >= 0 && parseFloat(remainingSecond / 60) <= res.data.data.machine_duration) {
                            setSecond(remainingSecond)
                        } else {
                            setSecond(res.data.second)
                        }
                        setRunning(true)
                    } else {
                        setSecond(parseFloat(res.data.data.machine_duration) * 60)
                        const socket = io({ auth: { transactionId } })
                        socket.on("startMachine", (data) => {
                            setLastTran(data.transaction)
                            const remainingTime = new Date(data.transaction.machine_end_time).getTime() - new Date().getTime()
                            const remainingSecond = Math.floor(remainingTime / 1000)
                            if (parseInt(remainingSecond / 60) >= 0 && parseFloat(remainingSecond / 60) <= data.transaction.machine_duration) {
                                setSecond(remainingSecond)
                            } else {
                                setSecond(data.second)
                            }
                            setRunning(true)
                        })

                        return () => {
                            socket.disconnect();
                        };
                    }
                })
                .catch(err => {
                    setShowTimer(false)
                });
        }

        if (!lastTran && transactionId && transactionType === "Subscription") {
            axios
                .post("/api/subscription/getTransactionDetails", { transactionId }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setLastTran(res.data.data)
                    let currencyCountry = res.data.data.admin.distributorId ? res.data.data.admin.distributor.country : res.data.data.admin.country
                    setCountry(currencyCountry)
                })
                .catch(err => {

                });
        }

        if (!payment.machine && STORETYPE === "cleanpro" && payment.outlet.machineNo && transactionType === "Transaction") {
            axios
                .post("/api/user/getMachine", { machineNo: payment.outlet.machineNo, operatorId })
                .then(res => {
                    if (res.data && res.data.data) {
                        const response = decrypt(res.data.data)
                        scanned(response);
                    }
                })
                .catch(err => {
                    mySwal.fire("Error", err.response.data.error, "error")
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (running) {
            startTimer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [second, running])

    const startTimer = () => {
        timer = setTimeout(() => {
            if (second > 0) {
                const remainingTime = new Date(lastTran.machine_end_time).getTime() - new Date().getTime()
                const remainingSecond = Math.floor(remainingTime / 1000)
                if (parseInt(remainingSecond / 60) >= 0 && parseFloat(remainingSecond / 60) <= lastTran.machine_duration) {
                    setSecond(remainingSecond)
                } else {
                    setSecond(second - 1)
                }
            } else {
                stopTimer()
                setCompleted(true)
                setRunning(false)
            }
        }, 1000)
    }

    const stopTimer = () => {
        clearTimeout(timer);
    }

    if (STORETYPE === "cleanpro" && payment.machine && (payment.machine.type === "Washer" || payment.machine.type === "Dryer") && transactionType === "Transaction") {
        return (
            <div id="thankyou-page">
                <div id="user-header">
                    <div>
                        <div className="d-flex justify-content-between pt-2">
                            <img className="plus-logo" src={cpPlusLogo} alt="" />
                            {
                                user ? (
                                    <h5 className="mt-2">
                                        <i onClick={(e) => { changeNav("camera"); history.push("/") }} className="bi bi-chevron-left me-3"></i>{t("Scan QR")}
                                    </h5>
                                ) : null
                            }
                        </div>
                        <div className="d-flex pb-3">
                            <div>
                                <img src={locationIcon} alt="" />
                            </div>
                            <p className="ml-1" style={{ fontSize: "11px", marginTop: "auto" }}>{payment.machine && payment.machine.outlet_fullname ? payment.machine.outlet_fullname : ""}</p>
                        </div>
                    </div>

                    <div>
                        <div id="machine-instruction-container">
                            <div id="machine-info-container">
                                <div id="machine-image-container">
                                    {payment.machine ? (
                                        <h3>{payment.machine.type === "Washer" ? t("Wash") : payment.machine.type === "Dryer" ? t("Dry") : ""} </h3>
                                    ) : null}

                                    {payment.machine ? (
                                        payment.machine.type === "Washer" ? (
                                            <img src={cpPlusWasher} alt="washer" className="img-fluid" />
                                        ) : payment.machine.type === "Dryer" ? (
                                            <img src={cpPlusDryer} alt="dryer" className="img-fluid" />
                                        ) : null
                                    ) : null}
                                </div>

                                <div id="machine-info">
                                    {payment.machine ? (
                                        <>
                                            <h5 className="m-0 p-0">
                                                {payment.machine.name}
                                            </h5>

                                            <div className="mt-2" id="badge-container">
                                                <div>
                                                    <p style={{ fontSize: "12px", color: "#525252" }}>{t("Capacity")}</p>
                                                    <strong style={{ fontSize: "16px" }}>{payment.machine.capacity}</strong>
                                                </div>
                                                <div>
                                                    <p style={{ fontSize: "12px", color: "#525252" }}>{t("Duration")}</p>
                                                    <strong style={{ fontSize: "16px" }}>
                                                        {lastTran ? lastTran.machine_total_duration : ""} {t("min")}
                                                    </strong>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </div>

                            <div id="machine-instruction" className="py-5 px-5">
                                <p style={{ fontSize: "18px", fontWeight: "500" }}>{payment.machine.type === "Washer" ? t("Press START on the machine to begin washing.") : t("Press START on the machine to begin drying.")}</p><br />
                                <p style={{ fontSize: "18px", fontWeight: "500" }}>{t("Reminder")}:</p>
                                <ol className="pt-2" style={{ fontSize: "16px", fontWeight: "300" }}>
                                    <li>{t("Remember to select the same settings you have paid for on the corresponding machine.")}</li><br />
                                    <li>{t("Please ensure that your laundry is inside the machine and door is properly shut.")}</li>
                                </ol>
                            </div>
                        </div>

                        <div>
                            <button id="back-to-homepage" style={{ position: "fixed", bottom: "3%", left: "10%", width: "80%" }} className="secondary-button form-control btn mb-3" onClick={() => { changeNav("stamp"); history.push('/') }}>{t("Go to homepage")}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (STORETYPE === "cleanpro") {
        return (
            <div>
                <img src={confettiGif} alt="" className="confetti-gif" />
                <div>
                    <div className="pt-5" style={{ margin: "auto", width: '50%' }}>
                        <img src={logoController(themeLogo)} alt="logo" className="img-fluid" />
                        <img className="pt-2" src={cpPlusBlueLogo} alt="" />
                    </div>
                    <div className="pt-5 text-center">
                        <h1><b>{lastTran && lastTran?.status === "Success" ? t("Payment Successful!") : t("Thank You!")}</b></h1>
                        <p className="pt-2" style={{ color: "#0c59a0", fontSize: "18px" }}><b>{transactionType === "Subscription" ? t("Thank you for purchasing Laundry Pass!") : transactionType === "Reload" ? t("Thank you for topup credits!") : t("Thank you for using our services.")}</b></p>
                    </div>
                    <div style={{ padding: "30px 30px 0px 30px" }}>
                        <button id="back-to-homepage" className="big-button form-control btn mb-3" onClick={() => history.push('/')}>{t("BACK TO HOMEPAGE")}</button>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="admin-bgImg">
                <div id="user-header">
                    <i onClick={e => history.push('/')} className="bi bi-chevron-left" id="thankyou-back-icon"></i>
                </div>
                <div>
                    <div style={{ margin: "auto", width: '50%' }}>
                        <img src={logoController(themeLogo)} alt="logo" className="img-fluid" />
                        {
                            STORETYPE === "cleanpro" ? (
                                <img className="pt-2" src={cpPlusLogo} alt="" />
                            ) : null
                        }
                    </div>
                    <div className="mt-3 px-3">

                        {
                            lastTran && lastTran?.status === "Success" ? (
                                <h1 className="thankyou-title text-center">{t("Payment Successful!")}</h1>
                            ) : (
                                <h1 className="thankyou-title text-center">{t("Thank You!")}</h1>
                            )
                        }
                        {
                            (lastTran && lastTran?.machine_type === "Washer") || (payment.machine && payment.machine.type === "Washer") ? (
                                <p style={{ fontSize: "18px" }} className="thankyou-subtitle text-center pt-2">{t("Hi")} {user ? user.name : t("customer")}! <Trans i18nKey="Thank you for your payment. Don't forget to select the <b>correct temperature</b> and press <b>START</b> when you're ready." components={{ b: <b /> }}>Thank you for your payment. Don't forget to select the <b>correct temperature</b> and press <b>START</b> when you're ready.</Trans></p>
                            ) : transactionType === "Subscription" && lastTran && lastTran?.status === "Success" ? (
                                <p style={{ fontSize: "18px" }} className="thankyou-subtitle text-center pt-2">{t("Thank you for purchasing Laundry Pass!")}</p>
                            ) : (
                                <p style={{ fontSize: "18px" }} className="thankyou-subtitle text-center pt-2">{t("Thank you for using our services.")}</p>
                            )
                        }

                        {
                            transactionType === "Subscription" && lastTran && lastTran?.status === "Success" ? (
                                <div className="pt-5 px-3">
                                    {lastTran.subscription_package?.discounted_price ? <h3 className="thankyou-subtitle text-center"><span style={{ fontSize: "30px" }}>{t("Save Big!")}</span> {t("You've saved")} <span style={{ fontSize: "30px" }}>{currency(country)} {/\./g.test(lastTran.subscription_package?.discounted_price.toString()) ? parseFloat(lastTran.subscription_package?.discounted_price).toFixed(2) : lastTran.subscription_package?.discounted_price}</span> {t("on your package!")}</h3> : null}
                                    <h3 className="thankyou-subtitle pt-4">{lastTran.name}</h3>
                                    <h3 className="thankyou-subtitle">{t("Wash")}: {lastTran.wash} | {t("Dry")}: {lastTran.dry}</h3>
                                    {lastTran.subscriptions[0] && lastTran.subscriptions[0].expired_date ? <h3 className="thankyou-subtitle">{t("Expired Date")}: {new Date(lastTran.subscriptions[0].expired_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</h3> : null}
                                </div>
                            ) : null
                        }

                        {/* {
                            showTimer ? (
                                <div className="pt-5">
                                    <div id="thankyou-machine-info-container">
                                        <div id="thankyou-machine-image-container">
                                            {
                                                lastTran.machine_type === "Washer" ? (
                                                    <img src={washer} alt="washer" className="img-fluid" />
                                                ) : lastTran.machine_type === "Dryer" ? (
                                                    <img src={dryer} alt="dryer" className="img-fluid" />
                                                ) : (
                                                    null
                                                )
                                            }
                                        </div>

                                        <div id="thankyou-machine-info">
                                            <div>
                                                <h5 className="m-0 p-0">{t(lastTran.machine_type)} - {lastTran.machine_name}</h5>
                                                <h6 className="m-0 p-0">{lastTran.machine_capacity}</h6>
                                                {
                                                    completed ? (
                                                        <h5 className="m-0 p-0 timer-title">{lastTran.machine_type === "Washer" ? t("Washing Complete") : lastTran.machine_type === "Dryer" ? t("Drying Complete") : ""}</h5>
                                                    ) : (
                                                        <h5 className="m-0 p-0 timer-title">{t("Time left")}: <b className="h3">{parseInt(second / 60).toString().length === 1 ? `0${parseInt(second / 60)}` : parseInt(second / 60)} : {parseInt(second % 60).toString().length === 1 ? `0${parseInt(second % 60)}` : parseInt(second % 60)}</b></h5>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        } */}

                        {
                            showTimer && STORETYPE !== "cleanpro" ? (
                                <div className="shadow px-5 py-4 mt-4" style={{ backgroundColor: "white" }}>
                                    <h4><b>{t("Finish in")} {parseInt(second / 60).toString().length === 1 ? `0${parseInt(second / 60)}` : parseInt(second / 60)} : {parseInt(second % 60).toString().length === 1 ? `0${parseInt(second % 60)}` : parseInt(second % 60)} {t("min")}</b></h4>
                                    {lastTran.machine_type === "Washer" ? <><small>{t("It's indicative and may change based on washer load")}</small><br /></> : null}
                                    <small>{t("Need to refresh to update")}</small>
                                    {lastTran.notification_type ? <p>{t("We'll let you know when it's ready to pick up")}</p> : null}
                                    <div className="d-flex">
                                        <div className="pe-1">
                                            <img src={laundroLogo} alt="" style={{ width: "25px" }} />
                                        </div>
                                        <ProgressBar now={100} style={{ height: "8px" }} />
                                        <div className="px-1">
                                            <img src={timerMachineIcon} alt="" style={{ width: "25px" }} />
                                        </div>
                                        <ProgressBar now={completed ? 100 : !running ? 0 : 100 - (second / (lastTran.machine_total_duration * 60)) * 100} style={{ backgroundColor: running ? "#B6D0E2" : "grey", height: "8px" }} />
                                        <div className="px-1">
                                            <img src={timerClothIcon} alt="" style={{ width: "25px" }} />
                                        </div>
                                        <ProgressBar now={completed ? 100 : 0} style={{ backgroundColor: "grey", height: "8px" }} />
                                        <div className="ps-1">
                                            <img src={timerHomeIcon} alt="" style={{ width: "25px" }} />
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div style={{ width: "80%" }}>
                                            <h4 className="pt-2">{lastTran.machine_name}</h4>
                                            <div className="d-flex">
                                                <p style={{ width: "50%" }}>{t("Capacity")}</p>
                                                <p style={{ width: "50%" }}>{t("Duration")}</p>
                                            </div>
                                            <div className="d-flex">
                                                <p style={{ width: "50%", lineHeight: "10%" }}><b>{lastTran.machine_capacity}</b></p>
                                                <p style={{ width: "50%", lineHeight: "10%" }}><b>{lastTran.machine_total_duration} {t("min")}</b></p>
                                            </div>
                                        </div>
                                        <div style={{ display: "block", margin: "auto" }}>
                                            <img src={machineIcon} alt="" style={{ width: "50px" }} />
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        }

                    </div>
                    <div style={{ padding: "30px 30px 0px 30px" }}>
                        <button id="back-to-homepage" className="big-button form-control btn mb-3" onClick={() => history.push('/')}>{t("BACK TO HOMEPAGE")}</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        themeLogo: state.theme.logo,
        payment: state.payment,
        user: state.user.user,
        transactionId: state.payment.transactionId,
        transactionType: state.payment.transactionType,
        operatorId: state.user.operatorId,
        token: state.user.token,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeNav: (nav) => dispatch(changeNav(nav)),
        scanned: (data) => dispatch(scannedCode(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Thankyou);